@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.App-header {
  position: relative;
  background-color: #ffffffd5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: rgb(0, 0, 0);
}

.App-title {
  font-size: 2rem;
  margin-top: 3rem;
}

.App-para {
  font-size: 1.2rem;
  margin: 0.5rem 0;
  margin-bottom: 1rem;
  max-width: 800px;
}

.App-video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.App-video-title {
  font-size: 1rem;
  font-weight: bold;
  margin-top: -1rem;
  display: block;
}

.App-logo {
  height: 40vmin;
  max-width: 80%;
  margin: 0rem;
  margin-bottom: 1rem;
  pointer-events: none;
}

/* .App-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 5px;
  font-weight: bold;

} */
/* .App-links h6 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.App-link {
  margin: 0.5rem 0;
} */

.App-links {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0px, 120px); /* adjust the values to your liking */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
  width: 250px;
}

.App-links h6 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  color: #29b831;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.App-link {
  margin: 0.5rem 0;
  color: #333;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.App-link:hover {
  color: #29b831;
}

/* @media (max-width: 768px) {
  .App-links {
    width: 100%;
  }
} */

@media screen and (max-width: 768px) {
  .App-links {
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    z-index: 1;
    padding: 0.2rem;
    margin-bottom: 20rem;
    background-color: #f0f0f0;
    border-radius: 0;
    transform: none;
  }

  .App-link {
    margin: 0.25rem 0;
  }

  .App-header {
    margin-top: 70px;
  }
}


.App-footer {
  background-color: #f0f0f0;
  padding: 0.5rem;
  text-align: center;
  font-size: 0.8rem;
  color: #999;
}

.App-copyright {
  color: #444444;
}
